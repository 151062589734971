<template>
  <router-link class="start" to="/qrscanner">
    <div class="step step1">
      <div>
        <h3>STEP 1</h3>
        <h2>Point at QR code</h2>
      </div>
      <img src="@/assets/images/scan_icon.svg" />
    </div>
    <div class="step step2">
      <div>
        <h3>STEP 2</h3>
        <h2>Point at image</h2>
      </div>
      <img src="@/assets/images/image_icon.svg" />
    </div>
    <div class="step step3">
      <div>
        <h3>STEP 3</h3>
        <h2>Repeat for the next 301</h2>
      </div>
      <img src="@/assets/images/repeat_icon.svg" />
    </div>
    <div class="btn btn--secondary" to="/qrscanner">STEP 1</div>
  </router-link>
</template>

<script>
export default {
  metaInfo: {
    title: 'Start',
  },
}
</script>

<style lang="scss" scoped>
  .start {
    height: 100vh;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 1rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    h3 {
      font-size: $text-large;
      font-weight: 700;
      margin: 0;
    }
    h2 {
      font-size: $text-medium;
      font-weight: 500;
      text-transform: lowercase;
      margin: 0;
    }
  }
  .btn {
    margin-left: auto;
    display: block;
  }
  .step {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
    &.step1 {
      color: $secondary;
    }
    &.step2 {
      color: $primary;
    }
    &.step3 {
      color: $white;
    }
    img {
      width: 60px;
      height: auto;
    }
  }
@media only screen and (max-width: 767px) and (min-width: 500px) {
  .step {
    margin-bottom: 1em;
  }
  .start {
    padding: 0 6rem;
  }
  .btn {
    width: 180px;
  }
}
</style>
