<template>
  <div class="c-qrtester">
    <qrcode-stream class="qr-scanner" @detect="onDetect" @decode="onDecode">
      <span v-if="qrResult" class="scan-info detected">{{ qrResult }}</span>
      <span v-else class="scan-info">point at QR code...</span>
    </qrcode-stream>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  data () {
    return {
      allowScan: false,
      qrResult: null,
    }
  },
  metaInfo: {
    title: 'QR tester',
  },
  components: {
    QrcodeStream,
  },
  mounted () {
    this.scanQR()
  },
  methods: {
    async onDetect (promise) {
      try {
        const {
          imageData,    // raw image data of image/frame
          content,      // decoded String
          location      // QR code coordinates
        } = await promise

        console.log(imageData,content,location);
      } catch (error) {
        console.log('error:', error)
        // ...
      }
    },
    scanQR () {
      this.scanningQR = true
    },
    stopScanningQR () {
      this.scanningQR = false
      this.qrResult = null
    },
    onDecode (decodedString) {
      console.log('decodedString:', decodedString)
      this.qrResult = decodedString
    }
  }
}
</script>

<style lang="scss" scoped>
  .c-qrtester {
    height: 100vh;
  }
  // .close-button {
  //   width: 30px;
  //   height: 30px;
  //   padding: 10px;
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   cursor: pointer;
  //   img {
  //     width: 100%;
  //     height: auto;
  //   }
  // }
  // .overlay {
  //   background-color: rgba(45,42,38,0.75);
  //   width: 100%;
  //   height: 100vh;
  //   display: flex;
  //   flex-wrap: wrap;
  //   justify-content: center;
  //   align-items: center;
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  // }
  // .scan-button {
  //   width: 300px;
  //   height: 300px;
  //   cursor: pointer;
  //   color: #fff;
  //   text-transform: uppercase;
  //   color: #fff;
  //   font-size: 40px;
  //   line-height: 1.2;
  //   font-weight: bold;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   opacity: 0.8;
  //   position: relative;
  //   img {
  //     position: absolute;
  //     top: 7px;
  //     left: -2px;
  //     width: 100%;
  //     height: auto;
  //   }
  //   span {
  //     position: relative;
  //     padding: 0 50px;
  //     opacity: 0.8;
  //   }
  // }
  .scan-info {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    font-size: 1.2rem;
    padding: 10px 20px;
    box-sizing: border-box;
    &.detected {
      top: 0;
      word-break: break-all;
      background-color: red;
    }
  }
  .qr-scanner {
    height: 100vh;
  }
</style>