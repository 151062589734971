<template>
  <div class="home">
    <div class="about" :class="{'is-open': pulldown}">
      <div class="wrapper">
        <div class="header">
          <h1>eeny meeny<br><span>motion pictures</span></h1>
          <div class="logo"><logo/></div>
        </div>
        <div class="text" v-html="about" />
        <div class="pullup" @click="pulldown=!pulldown">
          <svg version="1.1" viewBox="0 0 29.29 29.29" height="29.29" width="29.29">
            <path d="M 4.2421875 0 L 0 4.2421875 L 10.402344 14.644531 L 0 25.046875 L 4.2421875 29.289062 L 14.644531 18.886719 L 25.046875 29.289062 L 29.289062 25.046875 L 18.886719 14.644531 L 29.289062 4.2421875 L 25.046875 0 L 14.644531 10.402344 L 4.2421875 0 z " />
          </svg>
        </div>
      </div>
    </div>
    <div class="pulldown" @click="pulldown=!pulldown">i</div>
    <transition name="fadeslow">
      <div v-if="loaded" class="buttons">
        <router-link to="/start"><logo/></router-link>
        <router-link class="btn" to="/start">START</router-link>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import logo from '@/components/Logo.vue'
export default {
  data () {
    return {
      pulldown: false,
      loaded: false,
    }
  },
  components: {
    logo,
  },
  metaInfo: {
    title: 'EenyMeenyMotionPictures',
    titleTemplate: '%s',
  },
  computed: {
    ...mapGetters({
      about: "getAbout",
    }),
  },
  mounted () {
    this.loaded = true
  },
}
</script>

<style lang="scss" scoped>
  .home {
    height: 100vh;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  /deep/ .text {
    padding-bottom: 100px;
  }
  .buttons {
    text-align: center;
  }
  .btn {
    margin-top: 4rem;
  }
  .pulldown {
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 44px;
    height: 44px;
    cursor: pointer;
    background-color: $primary;
    color: $black;
    font-size: 32px;
    line-height: 44px;
    font-weight: bold;
    border-radius: 50%;
    text-align: center;
  }
  .pullup {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    right: 0;
    background-color: $black;
    cursor: pointer;
    text-align: right;
    box-shadow: 0 0 8px rgba(0,0,0,0.8);
    background-image: url('~@/assets/images/background.png');
    background-size: cover;
    svg {
      height: 34px;
      margin: 1rem 1rem 0.6rem 0;
      width: auto;
      fill: $white;
    }
  }
  .start_logo {
    width: 200px;
    height: auto;
  }
  .about {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $black;
    padding: 0 20px;
    box-sizing: border-box;
    font-size: $text-p;
    text-align: center;
    transform: translateX(-100%);
    transition: transform 0.3s;
    .header {
      display: flex;
      text-align: left;
      margin-top: 80px;
      margin-bottom: 20px;
      align-items: center;
      justify-content: space-between;
    }
    h1 {
      font-size: 2.2rem;
      line-height: 1;
      span {
        font-size: 75%;
      }
    }
    .logo {
      .start_logo {
        width: 80px;
      }
    }
    .wrapper {
      overflow: hidden;
      height: 100%;
      max-width: 800px;
      margin: 0 auto;
      padding-bottom: 100px;
    }
    &.is-open {
      transform: translateX(0%);
      .pulldown {
        display: none;
      }
      .wrapper {
        overflow: auto;
      }
    }
  }
  @media only screen and (max-width: 767px) and (min-width: 500px) {
    .home {
      flex-direction: row;
    }
    .start_logo {
      margin: 0;
      height: 200px;
      width: auto;
    }
    .btn {
      margin-top: 0;
      margin-left: 2rem;
    }
  }
</style>
