import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Qrtester from '../views/Qrtester.vue';
import Qrscanner from '../views/Qrscanner.vue';
import Start from '../views/Start.vue';
import Scenes from "../views/Scenes.vue";
import Vimeo from "../views/Vimeo.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/qrtester',
    name: 'QRtester',
    component: Qrtester,
  },
  {
    path: '/qrscanner',
    name: 'Qrscanner',
    component: Qrscanner,
  },
  {
    path: '/start',
    name: 'Start',
    component: Start,
  },
  {
    path: '/scenes',
    name: 'Scenes',
    component: Scenes,
  },
  // {
  //   path: '/scene/:id',
  //   name: 'Scene',
  //   component: () => import(/* webpackChunkName: "scene" */ '../components/Scene.vue'),
  // },
  // {
  //   path: '/video/:slug',
  //   name: 'Video',
  //   component: () => import(/* webpackChunkName: "video" */ '../components/Video.vue'),
  // },
  {
    path: '/v/:slug',
    name: 'Vimeo',
    component: Vimeo,
    // component: () => import(/* webpackChunkName: "video" */ '../components/Vimeo.vue'),
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
  {
    path: '*',
    name: '404',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/404.vue'),
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  routes,
});

export default router;
