import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import db from '../db'
import firebase from 'firebase/app'
import 'firebase/storage'



export default new Vuex.Store({
  state: {
    baseUrl: 'eeny.nl',
    messages: {
      scanText: 'point at QR code',
    },
    scenes: [],
    scenesLoaded: false,
    about: null,
  },
  getters: {
    getBaseUrl: state => {
      return state.baseUrl
    },
    getMessages: state => {
      return state.messages
    },
    scenesLoaded: state => {
      return state.scenesLoaded
    },
    getAbout: state => {
      return state.about
    },
    getScenes: state => {
      return state.scenes
    },
    getSceneBySlug: (state) => (slug) => {
      if (!state.scenes) return 'noooo'
      return state.scenes.filter(scene => {
        console.log(scene.slug, slug)
        return scene.slug == slug
      })
    },
  },
  mutations: {
    storeScenes(state, scenes) {
      // console.log('storeScenes:', scenes)
      state.scenes = scenes;
      state.scenesLoaded = true
    },
    storeAbout(state, about) {
      state.about = about;
    },
  },
  actions: {
    // GAAT NIET GOED HIER
    async getContent({ commit, state }) {
      // console.log('state.scenes.length:', state.scenes.length)
      if (!state.scenes.length) {
        // console.log('get content');
        firebase.auth().signInAnonymously()
          .then(() => {
            db.collection('fl_content')
              .get()
              .then(querySnapshot => {
                const query = querySnapshot.docs.map(doc => doc.data())
                let scenes = []
                let about = ''
                query.forEach(doc => {
                  // console.log('doc:', doc)
                  if (doc._fl_meta_.schema == 'scenes') {
                    if (!doc.markerPattern.length) return
                    let cleaned = {}
                    cleaned.id = doc.id
                    cleaned.title = doc.title
                    cleaned.slug = doc.slug
                    cleaned.credits = doc.credits ? doc.credits : null
                    cleaned.vimeoUrl = doc.vimeoUrl
                    cleaned.markerId = doc.markerPattern[0].id
                    cleaned.markerImage = doc.markerImage && doc.markerImage.length ? doc.markerImage[0].id : null
                    cleaned.qrCode = doc.qrCode && doc.qrCode.length ? doc.qrCode[0].id : null
                    scenes.push(cleaned)
                  }
                  if (doc._fl_meta_.schema == 'about') {
                    about = doc.content
                  }
                })
                commit('storeScenes', scenes)
                commit('storeAbout', about)
              })
          })
          .catch((error) => {
            console.log(error);
          })
      }
    },
  },
  modules: {
  },
});

