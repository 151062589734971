import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
// meta
import VueMeta from 'vue-meta'
Vue.use(VueMeta)
Vue.config.productionTip = false
// analytics
import VueGtag from 'vue-gtag'
Vue.use(VueGtag, {
  config: { id: "UA-198437472-1" },
  appName: 'Eeny Meeny',
  pageTrackerScreenviewEnabled: true
}, router)

// fonts
require('typeface-nunito-sans')
require('typeface-lato')

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
