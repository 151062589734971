<template>
  <div v-if="sceneData.length" class="c-scenes">
    <p>There are <strong>{{getScenes.length}}</strong> scenes, of which <strong>{{sceneData.length}}</strong> are valid.</p>
    <div v-for="scene in sceneData" :key="scene.id">
      <router-link :to="publicPath+'v/'+scene.slug">{{scene.title}}</router-link>
      <a v-if="scene.qrCodeUrl" :href="scene.qrCodeUrl" @click.prevent="modal = {qr: scene.qrCodeUrl}">
        <img :src="scene.qrCodeUrl" width="40px" height="40px" />
      </a>
      <a v-if="scene.markerUrl" :href="scene.markerUrl" @click.prevent="modal = {qr: scene.qrCodeUrl?scene.qrCodeUrl:false, image: scene.markerUrl}">
        <img :src="scene.markerUrl" width="40px" height="40px" />
      </a>
      <a :href="scene.vimeoUrl" target="_blank">
        <img src="@/assets/images/vimeo.svg" width="40px" height="40px" />
      </a>
    </div>
    <div v-if="modal" class="c-modal">
      <img v-if="modal.qr && modal.image" :src="modal.qr" class="qr" width="80px" height="80px" />
      <img class="marker" :src="modal.image ? modal.image : modal.qr" />
      <div class="close" @click="modal=false">
        <svg version="1.1" viewBox="0 0 29.29 29.29" height="29.29" width="29.29">
          <path d="M 4.2421875 0 L 0 4.2421875 L 10.402344 14.644531 L 0 25.046875 L 4.2421875 29.289062 L 14.644531 18.886719 L 25.046875 29.289062 L 29.289062 25.046875 L 18.886719 14.644531 L 29.289062 4.2421875 L 25.046875 0 L 14.644531 10.402344 L 4.2421875 0 z " />
        </svg>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import db from '../db'
import firebase from 'firebase/app'
import 'firebase/storage'

export default {
  data () {
    return {
      publicPath: process.env.BASE_URL,
      sceneData: [],
      modal: false,
    }
  },
  computed: {
    ...mapGetters([
      'getScenes',
      'scenesLoaded',
    ]),
  },
  watch: {
    '$store.state.scenesLoaded': {
      deep: true,
      handler(newVal, oldVal) {
        if (!this.initialized) this.init()
      }
    },
  },
  mounted () {
    // console.log('mounted');
    if (this.scenesLoaded) this.init()
  },
  methods: {
    async init () {
      for (const scene of this.getScenes) {
        if (scene.qrCode) {
          const file = await this.getMarkerFile(scene.qrCode)
          if (file) {
            const url = await this.getImageUrl(file)
            scene.qrCodeUrl = url
          }
        }
        if (scene.markerImage) {
          const file = await this.getMarkerFile(scene.markerImage)
          if (file) {
            const url = await this.getImageUrl(file)
            scene.markerUrl = url
          }
        }
        if (scene.vimeoUrl.includes('player.vimeo.com/external/') && scene.markerImage && scene.markerUrl) {
          this.sceneData.push(scene)
        }
      }
      this.sceneData.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase()) ? 1 : -1)
    },
    async getMarkerFile (id) {
      const url = await db.collection('fl_files')
        .doc(id)
        .get()
        .then(querySnapshot => {
          const file = querySnapshot.data()
          return file ? file.file : false
        })
      return url
    },
    async getImageUrl (fileName) {
      const url = await firebase.storage().ref('flamelink/media/'+fileName).getDownloadURL().then(function (url) {
        return url
      })
      return url
    },

  }
}
</script>

<style lang="scss" scoped>
.c-modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  .marker {
    width: 80%;
    height: 80%;
    object-fit: contain;
  }
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    cursor: pointer;
    svg {
      width: 100%;
      height: auto;
      fill: $black;
    }
  }
  @media (max-width: 500px) {
    .qr {
      position: absolute;
      bottom: 20px;
      height: 10%;
      width: auto;
    }
  }
}
.c-scenes {
  padding: 50px 1em;
  max-width: 800px;
  margin: 0 auto;
  div {
    display: flex;
    align-items: center;
  }
  a {
    margin-right: 1em;
    text-decoration: none;
    &:nth-child(2) {
      margin-left: auto;
    }
  }
  p {
    background-color: $secondary;
    text-align: center;
    text-transform: uppercase;
    padding: 0.5em;
    color: #fff;
  }
}
</style>