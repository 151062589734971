import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB0QTKE47x9atkpKHBv5N0iLwNpU0raUCA",
  authDomain: "eeny-meeny.firebaseapp.com",
  databaseURL: "https://eeny-meeny.firebaseio.com",
  projectId: "eeny-meeny",
  storageBucket: "eeny-meeny.appspot.com",
  messagingSenderId: "126767412106",
  appId: "1:126767412106:web:72c5e46c70b0d0415ece73",
  // measurementId: "G-96HLTWLQ1K"
};

// Get a Firestore instance
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
db.settings({
  timestampsInSnapshots: true
});

db.enablePersistence();

export default db
