<template>
  <div id="app" :class="$route.name">
    <router-view/>
    <router-link v-if="!isHome && !showInfo" class="home-button" :class="{'secondary': isScanner}" to="/">
      <svg viewBox="0 0 47.480469 47.480469" height="47.480469" width="47.480469">
        <path d="M 23.732422,3.2132496e-7 A 23.740509,23.740509 0 0 0 0,23.740235 23.740509,23.740509 0 0 0 23.740234,47.480469 23.740509,23.740509 0 0 0 47.480468,23.740235 23.740509,23.740509 0 0 0 23.740234,3.2132496e-7 a 23.740509,23.740509 0 0 0 -0.0078,0 z M 23.636722,7.1542975 41.455082,23.646485 H 35.410156 V 37.906251 H 27.349609 V 26.029298 H 20.148437 V 37.914063 H 11.939453 V 23.626954 L 7.3027344,23.525391 Z" />
      </svg>
    </router-link>
  </div>
</template>

<script>
// import Info from '@/components/Info'
import { mapGetters } from 'vuex'

export default {
  metaInfo: {
    title: 'EenyMeenyMotionPictures',
    titleTemplate: '%s | EenyMeenyMotionPictures',
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'This project consists of 300 short films made by Coco Koldijk and Claire Meekel in the context of lockdown cinema.' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=3.0, viewport-fit=cover' },
      { name: 'apple-mobile-web-app-capable', content: 'yes' },
      { name: 'apple-mobile-web-app-status-bar-style', content: 'black' }
    ]
  },
  // components: {
  //   Info,
  // },
  data () {
    return {
      showInfo: false,
    }
  },
  computed: {
    isHome () {
      return this.$route.name == 'Home'
    },
    isScanner () {
      return this.$route.name == 'Qrscanner'
    },
    hideInfo () {
      return this.$route.name == 'Scenes'
    },
    ...mapGetters([
      'getScenes',
      'scenesLoaded',
    ]),
  },
  created() {
    // this.$store.dispatch('signIn')
    // this.$store.dispatch('getUserID')
    // console.log('app created', this.scenesLoaded);
    if (!this.scenesLoaded) this.$store.dispatch('getContent')
  },
}
</script>

<style lang="scss">

html {
  font-size: 18px;
  @media only screen and (max-width: 374px) {
    font-size: 16px;
  }
}
body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-color: $black;
  background-image: url('~@/assets/images/background.png');
  background-size: contain;
}
h1 {
  margin: 0;
  font-size: $text-large;
  font-weight: 800;
}

p {
  margin: 0 0 1em;
  line-height: 1.5;
}

a {
  color: $primary
}

#app {
  font-family: 'Lato', -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $primary;
  background-color: transparent;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  &.Scenes {
    overflow: auto;
  }
}
.btn {
  padding: 0.3em 1.5em;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
  font-size: $text-large;
  border: 4px solid $primary;
  color: $primary;
  border-radius: 6px;
  text-align: center;
  img, svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  svg {
    fill: $primary;
  }
  &--text {
    width: auto;
    height: auto;
    padding: 0;
    line-height: 1;
  }
  &--error {
    border-color: $secondary;
    color: $secondary;
  }
  &--white {
    border-color: $white;
    color: $white;
  }
  &--secondary {
    border-color: $secondary;
    color: $secondary;
  }
  &--success {
    border-color: $green;
    color: $green;
  }
  &--small {
    border-width: 3px;
    font-size: 1rem;
    padding: 0.4em;
  }
}

.home-button {
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 8888;
  svg {
    width: 44px;
    height: auto;
    path {
      fill: $primary;
    }
  }
  &.secondary {
    path {
      fill: $secondary;
    }
  }
}

// .info-button {
//   position: fixed;
//   bottom: 20px;
//   left: 10px;
//   z-index: 900;
//   font-style: italic;
//   font-size: 1.8rem;
//   padding: 0;
//   text-align: center;
// }
// .fullscreen-button {
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   z-index: 900;
// }
// .scan-info {
//   position: absolute;
//   top: calc(50vh - 5rem);
//   left: 0;
//   width: 100%;
//   font-size: $text-medium;
//   font-weight: 600;
//   text-align: center;
//   .btn {
//     position: absolute;
//     bottom: -3.3rem;
//     left: 50%;
//     transform: translateX(-50%);
//     white-space: pre;
//   }
// }
.has-shadow {
  text-shadow: 1px 1px 4px #000;
}
.init-error {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f81b8b;
  z-index: 9999;
  text-align: center;
  font-size: 1.4rem;
  background-color: rgba(0,0,0,0.8);
  padding: 0 20px;
  box-sizing: border-box;
  svg {
    fill: #f81b8b;
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  }
}

.loader {
  top: 0;
  left: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  // background-image: url('~@/assets/images/background.png');
  // background-size: cover;
  // background-position: center;
  z-index: 9999;
  &.has-loaded {
    display: none;
  }
  svg {
    width: 50px;
    height: 50px;
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    fill: $primary;
    animation: spin 1s infinite linear;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.fadeslow-enter-active, .fadeslow-leave-active {
  transition: all 0.4s 0.1s cubic-bezier(0.18,0.89,0.32,1.27);
}
.fadeslow-enter, .fadeslow-leave-to {
  opacity: 0;
  transform: scale(0.6);
}
</style>
