<template>
  <div>
    <div v-if="!hasPlayedFully" class="next" @click="closeScene(true)"><img src="@/assets/images/scan_icon.svg" /></div>
    <transition name="fade">
      <div v-if="!ready" class="loader">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
          <path d="M256 512c-68.38 0-132.667-26.629-181.020-74.98-48.351-48.353-74.98-112.64-74.98-181.020 0-48.419 13.591-95.567 39.303-136.346 25-39.648 60.332-71.686 102.178-92.65l21.5 42.916c-34.019 17.042-62.746 43.093-83.075 75.335-20.873 33.104-31.906 71.399-31.906 110.745 0 114.691 93.309 208 208 208s208-93.309 208-208c0-39.346-11.033-77.641-31.905-110.745-20.33-32.242-49.057-58.292-83.075-75.335l21.5-42.916c41.846 20.964 77.179 53.002 102.178 92.65 25.711 40.779 39.302 87.927 39.302 136.346 0 68.38-26.629 132.667-74.98 181.020-48.353 48.351-112.64 74.98-181.020 74.98z"></path>
        </svg>
      </div>
    </transition>
    <div v-show="ready" ref="arcontainer" class="arcontainer">
      <!-- <router-link v-if="!hasPlayedFully" class="btn scan-button" to="/qrscanner">
        <img src="@/assets/images/scan_icon.svg" />
      </router-link> -->
      <div v-if="debug && sceneData" style="color: red; position: fixed; top: 10px; left: 90px;">
        {{sceneData.title}}
      </div>
      <div v-if="debug && markerVisible" style="color: red;">Marker detected...</div>
      <div v-if="debug" ref="stats" />
      <video
        ref="video"
        muted
        playsinline
        style="display:none"
        crossorigin="anonymous"
        preload="true"
      >
        <source v-if="sceneData && realVimeoUrl" :src="realVimeoUrl" type='video/mp4'>
      </video>
      <transition name="fade">
        <div v-if="!overlay && !markerVisible && !hasPlayedFully && hasPlayed" class="overlay">
          <p>keep the<br>black frame<br>in view</p>
        </div>
      </transition>
      <transition name="fade">
        <div v-if="overlay" class="overlay" @click="start">
          <p v-if="videoReady" class="tap">Tap</p>
          <p v-else>loading video...</p>
        </div>
      </transition>
      <transition name="fade">
        <div v-if="!overlay && !markerVisible && !hasPlayedFully && !hasPlayed" class="scan-info">
          <div class="top"></div>
          <div class="middle">
            <div class="side"></div>
            <div class="scan">
              <img src="@/assets/images/image_icon.svg" />
            </div>
            <div class="side"></div>
          </div>
          <div class="bottom">
            <p>scan image</p>
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div v-if="hasPlayedFully" class="overlay after-play">
          <div class="icon" @click="closeScene(true)"><img src="@/assets/images/next_eeny.svg" /></div>
          <div class="icon" @click="watchAgain"><img src="@/assets/images/watch_again.svg" /></div>
          <div class="btn btn--white btn--small pulldown" @click="pulldown=!pulldown">credits</div>
        </div>
      </transition>
      <div v-if="sceneData.credits && sceneData.credits.length" class="overlay credits" :class="{'is-open': pulldown}">
        <div class="wrapper">
          <!-- <h1>{{ sceneData.title }}</h1>
          <h2>credits</h2> -->
          <div class="text" v-html="sceneData.credits" />
          <div class="logos">
            <img src="@/assets/images/logos.png" />
          </div>
          <div class="close" @click="pulldown=!pulldown">
            <svg version="1.1" viewBox="0 0 29.29 29.29" height="29.29" width="29.29">
              <path d="M 4.2421875 0 L 0 4.2421875 L 10.402344 14.644531 L 0 25.046875 L 4.2421875 29.289062 L 14.644531 18.886719 L 25.046875 29.289062 L 29.289062 25.046875 L 18.886719 14.644531 L 29.289062 4.2421875 L 25.046875 0 L 14.644531 10.402344 L 4.2421875 0 z " />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div v-if="initError" class="init-error">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="768" height="768" viewBox="0 0 768 768">
        <path d="M736 384c0-97.184-39.424-185.248-103.104-248.896s-151.712-103.104-248.896-103.104-185.248 39.424-248.896 103.104-103.104 151.712-103.104 248.896 39.424 185.248 103.104 248.896 151.712 103.104 248.896 103.104 185.248-39.424 248.896-103.104 103.104-151.712 103.104-248.896zM672 384c0 79.552-32.192 151.488-84.352 203.648s-124.096 84.352-203.648 84.352-151.488-32.192-203.648-84.352-84.352-124.096-84.352-203.648 32.192-151.488 84.352-203.648 124.096-84.352 203.648-84.352 151.488 32.192 203.648 84.352 84.352 124.096 84.352 203.648zM537.6 492.8c0 0-4.192-5.536-10.592-12.256-4.64-4.896-10.816-10.912-18.464-17.28-10.784-8.992-24.704-18.88-41.504-27.296-22.56-11.264-50.528-19.968-83.040-19.968s-60.48 8.704-83.040 19.968c-16.8 8.416-30.72 18.304-41.504 27.296-7.648 6.368-13.824 12.384-18.464 17.28-6.4 6.72-10.592 12.256-10.592 12.256-10.592 14.144-7.744 34.208 6.4 44.8s34.208 7.744 44.8-6.4c1.312-1.6 5.792-6.592 5.792-6.592 3.2-3.36 7.584-7.648 13.056-12.224 7.776-6.496 17.568-13.408 29.12-19.2 15.36-7.648 33.6-13.184 54.432-13.184s39.072 5.536 54.432 13.216c11.52 5.76 21.344 12.704 29.12 19.2 5.472 4.576 9.856 8.864 13.056 12.224 4.48 4.96 5.792 6.56 5.792 6.56 10.592 14.144 30.656 16.992 44.8 6.4s16.992-30.656 6.4-44.8zM288 320c17.664 0 32-14.336 32-32s-14.336-32-32-32-32 14.336-32 32 14.336 32 32 32zM480 320c17.664 0 32-14.336 32-32s-14.336-32-32-32-32 14.336-32 32 14.336 32 32 32z"></path>
      </svg>
      {{ initError }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as Stats from 'stats.js'
import db from '../db'
import firebase from 'firebase/app'
import 'firebase/storage'

export default {
  metaInfo () {
    return {
      title: this.sceneData ? this.sceneData.title : null,
    }
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      sceneWidth: 640,
      sceneHeight: 480,
      sceneNumber: this.$route.params.id,
      scene: null,
      stats: null,
      statsDom: null,
      camera: null, 
      renderer: null, 
      clock: null, 
      deltaTime: null, 
      totalTime: null,
      arToolkitSource: null,
      arToolkitContext: null,
      markerRoot: [],
      markerVisible: false,
      debug: this.$route.query.debug ? true : false,
      overlay: false,
      slug: null,
      playPromise: null,
      videoReady: false,
      animationLoop: null,
      ready: false,
      initialized: false,
      sceneData: null,
      patternUrl: null,
      markerBorder: 0.8,
      initError: false,
      interval: null,
      realVimeoUrl: null,
      hasPlayed: false,
      hasPlayedFully: false,
      isPaused: false,
      pulldown: false,
    }
  },
  computed: {
    ...mapGetters([
      'getScenes',
      'scenesLoaded',
    ]),
  },
  watch: {
    '$store.state.scenesLoaded': {
      deep: true,
      handler(newVal, oldVal) {
        console.log('scenesLoaded',newVal, oldVal);
        if (!this.initialized) this.init()
      }
    },
  },
  mounted () {
    // console.log(this.$route);
    // console.log('mounted vimeo');
    if (this.scenesLoaded) this.init()
    // this.$nextTick(() => {
    //   if (this.ready) {
    //     this.init()
    //     console.log('init on mounted')
    //   }
    // })
  },
  methods: {
    init () {
      this.slug = this.$route.params.slug
      this.getScenes.forEach(scene => {
        if (scene.slug == this.slug) {
          this.sceneData = scene
          this.markerId = scene.markerId
          this.getMediaURLForVimeo(this.sceneData.vimeoUrl) // get real akamai url from vimeo proxy
        }
      })
      this.getMarkerFile().then((fileName) => {
        if (fileName) {
          this.getPatternUrl(fileName).then((url) => {
            this.patternUrl = url
            this.initialized = true
            this.initialize()
            this.animate()
            this.play()
          })
        } else {
          this.initError = 'Cannot load a valid marker pattern file. Please contact Eeny support.'
        }
      })
    },
    start () {
      this.overlay = false
      this.play()
      // console.log('start');
    },
    initialize () {
      this.checkVideoReady()
      // setup THREE scene
      this.scene = new THREE.Scene();

      // let ambientLight = new THREE.AmbientLight(0xffffff, 1);
      // this.scene.add(ambientLight);

      // this.camera = new THREE.PerspectiveCamera( 45, 1, 0, 10 );
      // this.scene.add( this.camera );

      this.camera = new THREE.Camera();
      this.scene.add(this.camera);

      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true
      });
      // this.renderer.setClearColor(new THREE.Color('lightgrey'), 0)
      this.renderer.setSize(this.sceneWidth*1.5, this.sceneHeight*1.5); // double video render resolution
      this.renderer.domElement.style.position = 'absolute'
      this.renderer.domElement.style.top = '0px'
      this.renderer.domElement.style.left = '0px'
      this.$refs.arcontainer.appendChild(this.renderer.domElement);

      this.clock = new THREE.Clock();
      this.deltaTime = 0;
      this.totalTime = 0;
      
      // stats
      if (this.debug) {
        this.stats = new Stats();
        this.statsDom = this.stats.dom
        this.$refs.stats.appendChild(this.stats.dom)
      }

      ////////////////////////////////////////////////////////////
      // setup arToolkitSource
      ////////////////////////////////////////////////////////////

      this.arToolkitSource = new THREEx.ArToolkitSource({
        sourceType: 'webcam',
      })


      this.arToolkitSource.init(() => {
        // delay resize for iOS
        setTimeout(() => {
          this.onResize()
          this.ready = true     
        }, 200)
      })

      // handle resize event
      window.addEventListener('resize', () => {
        this.onResize()
      })

      ////////////////////////////////////////////////////////////
      // setup arToolkitContext
      ////////////////////////////////////////////////////////////	

      // create atToolkitContext
      this.arToolkitContext = new THREEx.ArToolkitContext({
        cameraParametersUrl: '../camera_para.dat',
        detectionMode: "color_and_matrix",
        matrixCodeType: "4x4",
        debug: this.debug,
        patternRatio: this.markerBorder,
        canvasWidth: this.sceneWidth,
        canvasHeight: this.sceneHeight,
      }, {
        sourceWidth: this.sceneWidth,
        sourceHeight: this.sceneHeight,
      })

      // copy projection matrix to camera when initialization complete
      this.arToolkitContext.init(this.onCompleted)


      ////////////////////////////////////////////////////////////
      // setup markerRoots
      ////////////////////////////////////////////////////////////

      this.markerRoot.root = new THREE.Group();
      this.markerRoot.slug = this.slug
      this.scene.add(this.markerRoot.root)
      this.markerRoot.control = new THREEx.ArMarkerControls(this.arToolkitContext, this.markerRoot.root, {
        type: 'pattern',
        patternUrl: this.patternUrl,
      })
      // background plane
      // this.markerRoot.bg_geometry = new THREE.PlaneBufferGeometry( 1, 1 )
      // this.markerRoot.bg_material = new THREE.MeshBasicMaterial( {color: 0x000000} )
      // this.markerRoot.bg_mesh = new THREE.Mesh( this.markerRoot.bg_geometry, this.markerRoot.bg_material )
      // this.markerRoot.bg_mesh.scale.x = this.markerBorder + 0.05
      // this.markerRoot.bg_mesh.scale.y = this.markerBorder + 0.05
      // this.markerRoot.bg_mesh.rotation.x = -Math.PI / 2
      // this.markerRoot.bg_mesh.position.y = -0.01
      // this.markerRoot.root.add( this.markerRoot.bg_mesh )
      // video plane
      this.markerRoot.geometry = new THREE.PlaneBufferGeometry(1, 1)
      // this.markerRoot.edges = new THREE.EdgesGeometry( this.markerRoot.geometry );
      // this.markerRoot.line = new THREE.LineSegments( this.markerRoot.edges, new THREE.LineBasicMaterial( { color: 0x1be9f8, linewidth: 4 } ) );
      // this.markerRoot.line.rotation.x = -Math.PI / 2
      // this.markerRoot.line.scale.x = this.markerBorder+0.02
      // this.markerRoot.line.scale.y = this.markerBorder+0.02
      // this.markerRoot.line.position.y = 0.01

      this.markerRoot.video = this.$refs['video']
      this.markerRoot.texture = new THREE.VideoTexture(this.markerRoot.video)
      // this.markerRoot.texture.minFilter = THREE.LinearFilter
      // this.markerRoot.texture.magFilter = THREE.LinearFilter
      // this.markerRoot.texture.format = THREE.RGBFormat
      this.markerRoot.material = new THREE.MeshBasicMaterial({ map: this.markerRoot.texture })
      this.markerRoot.mesh = new THREE.Mesh(this.markerRoot.geometry, this.markerRoot.material)
      this.markerRoot.mesh.rotation.x = -Math.PI / 2
      this.markerRoot.mesh.scale.x = this.markerBorder+0.01
      this.markerRoot.mesh.scale.y = this.markerBorder+0.01
      // this.markerRoot.root.add(this.markerRoot.line)
      this.markerRoot.root.add(this.markerRoot.mesh)
      // loader mesh
      this.markerRoot.loadergeometry = new THREE.RingGeometry( 3, 4, 4 )
      this.markerRoot.loadermaterial = new THREE.MeshBasicMaterial( { color: 0x1be9f8 } )
      this.markerRoot.loadermesh = new THREE.Mesh( this.markerRoot.loadergeometry, this.markerRoot.loadermaterial )
      this.markerRoot.loadermesh.rotation.x = -Math.PI / 2
      this.markerRoot.loadermesh.scale.x = 0.1
      this.markerRoot.loadermesh.scale.y = 0.1
      this.markerRoot.loadermesh.position.y = 0.1
      this.markerRoot.root.add( this.markerRoot.loadermesh )
      // play button mesh
      // const triangle = new THREE.Shape()
			// 		.moveTo( 0, 0 )
			// 		.lineTo( 5, 3 )
			// 		.lineTo( 0, 6 )
      //     .lineTo( 0, 0 )
      // this.markerRoot.playgeometry = new THREE.ShapeBufferGeometry(triangle)
			// this.markerRoot.playmesh = new THREE.Mesh( this.markerRoot.playgeometry, this.markerRoot.loadermaterial );
      // this.markerRoot.playmesh.rotation.x = -Math.PI / 2
      // this.markerRoot.playmesh.scale.x = 0.1
      // this.markerRoot.playmesh.scale.y = 0.1
      // this.markerRoot.playmesh.position.z = 0.3 // vert
      // this.markerRoot.playmesh.position.x = -0.2 // hor
      // this.markerRoot.playmesh.position.y = 0.1
      // this.markerRoot.root.add( this.markerRoot.playmesh )
      console.log('initialized');
    },
    onResize() {
      this.arToolkitSource.onResizeElement()
      this.arToolkitSource.copyElementSizeTo(this.renderer.domElement)
      if (this.arToolkitContext.arController !== null) {
        this.arToolkitSource.copyElementSizeTo(this.arToolkitContext.arController.canvas)
      }
    },
    onCompleted () {
      this.camera.projectionMatrix.copy(this.arToolkitContext.getProjectionMatrix())
    },
    update () {
      if (this.debug) this.stats.update()
      // update artoolkit on every frame
      if (this.arToolkitSource.ready !== false)
        this.arToolkitContext.update(this.arToolkitSource.domElement)
      // console.log('this.markerRoot:', this.markerRoot)
      this.markerVisible = this.markerRoot.root.visible
      if (this.markerVisible && !this.isPaused) {
        this.$refs.video.muted = false
        if (this.$refs.video.paused) this.play()
      } else {
        if (!this.$refs.video.paused) this.pause()
      }
      // animate loader
      if (this.markerRoot.loadermesh) this.markerRoot.loadermesh.rotation.z += 0.2
    },
    checkVideoReady () {
      let counter = 0
      this.interval = setInterval(()=>{
        counter++
        if(this.$refs.video.readyState >= 3){
          this.videoReady = true
          clearInterval(this.interval)
          console.log('video ready to play')
          this.removeMesh(this.markerRoot.loadermesh)
          const vm = this
          // set flag when video played 1 time
          this.$refs.video.addEventListener('timeupdate', () => {
            if (this.$refs.video.currentTime > 2) vm.hasPlayed = true
          })
          // video has ended
          this.$refs.video.addEventListener('ended', () => {
            vm.$refs.video.currentTime = vm.$refs.video.duration
            vm.isPaused = true
            vm.track()
            setTimeout(() => {
              vm.hasPlayedFully = true
            }, 1000);
          })
        }
        // video cant be loaded after 15 seconds
        if (counter >= 150) {
          clearInterval(this.interval)
          this.initError = 'Eeny cannot load the video for this image. Maybe it doesn\'t exist or internet is really slow? Try to refresh the page.' 
        }        
      },100)
    },
    removeMesh (mesh) {
      this.markerRoot.root.remove(mesh)
    },
    render () {
      this.renderer.render(this.scene, this.camera);
    },
    animate () {
      this.animationLoop = requestAnimationFrame(this.animate)
      // this.deltaTime = this.clock.getDelta()
      // this.totalTime += this.deltaTime
      this.update()
      this.render()
    },
    closeScene (next) {
      clearInterval(this.interval)
      cancelAnimationFrame(this.animationLoop)
      const arcam = document.getElementById('arjs-video')
      if (arcam) arcam.remove()
      if (next) this.$router.push({ name: 'Qrscanner'})
    },
    watchAgain () {
      this.$refs.video.currentTime = 0
      this.hasPlayed = false
      this.hasPlayedFully = false
      this.isPaused = false
    },
    pause () {
      if (this.playPromise) {
        this.playPromise.then(_ => {
          this.$refs.video.pause()
          this.$refs.video.removeEventListener('timeupdate')
        })
        .catch(error => {
          console.log('error pausing video:', error)
        });
      }
    },
    async play() {
      // console.log('play called');
      const player = this.$refs.video
      this.playPromise = player.play()
      if (player.currentTime < 3) player.currentTime = 0
      if (this.playPromise) {
        this.playPromise.then(_ => {
          // console.log('playing')
          player.muted = false
          this.overlay = false
          // this.removeMesh(this.markerRoot.playmesh)
        })
        .catch(error => {
          console.log('error playing video:', error)
          this.overlay = true
        });
      }
    },
    async getMarkerFile () {
      const url = await db.collection('fl_files')
        .doc(this.markerId)
        .get()
        .then(querySnapshot => {
          const file = querySnapshot.data()
          return file ? file.file : false
        })
      // console.log('getMarkerFile:', url)
      return url
    },
    async getPatternUrl (fileName) {
      const url = await firebase.storage().ref('flamelink/media/'+fileName).getDownloadURL().then(function (url) {
        return url
      })
      // console.log('getPatternUrl', url);
      return url
    },
    async getMediaURLForVimeo(url) {
      await fetch(url, {
        method: 'HEAD'
      })
      .then((response) => {
        // console.log('getMediaURLForVimeo:', response.url)
        this.realVimeoUrl = response.status === 200 ? response.url : this.initError = 'Cannot get video url from Vimeo proxy'
      });
    },
    track () {
      this.$gtag.event('video_played_fully', {
        'event_category': 'videos',
        'event_label': this.sceneData.title,
        'event_value': this.$route.params.slug,
      })
    },
  },
  beforeDestroy () {
    this.closeScene()
  },
}
</script>

<style lang="scss" scoped>
  .overlay {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 8888;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      color: $primary;
      font-size: $text-large;
      font-weight: 600;
      width: 100%;
      text-align: center;
      &.tap {
        font-size: 2.5rem;
      }
    }
    &.after-play {
      z-index: 8000;
      justify-content: start;
      padding-top: 50px;
      background-color: rgba(0,0,0,0.5);
      div:first-child {
        margin: 3rem 0 3rem;
      }
      .icon img {
        width: 180px;
        height: auto;
      }
    }
  }
  .scan-info {
    text-align: center;
    color: $primary;
    height: 100%;
    .top,
    .side,
    .bottom {
      background-color: rgba(0,0,0,0.5);
    }
    .top {
      height: calc(50vh - 180px);
    }
    .bottom {
      height: calc(50vh - 120px);
    }
    .bottom {
      padding-top: 2em;
    }
    .middle {
      height: 300px;
      display: flex;
    }
    .side {
      width: calc(50% - 150px);
    }
    .scan {
      width: 300px;
      position: relative;
      border: 30px solid $primary;
      opacity: 0.7;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      padding: 0 1rem;
      font-size: $text-huge;
      font-weight: 600;
      margin-bottom: 0;
    }
    img {
      width: 100px;
      height: auto;
    }
  }
  .pulldown{
    position: absolute;
    display: block;
    top: 1rem;
    right: 1rem;
    // z-index: 700;
  }
  .next {
    position: fixed;
    right: 1rem;
    top: 1rem;
    z-index: 9000;
    img {
      width: 44px;
      height: auto;
    }
  }
  .credits {
    background-color: rgba($black, 0.9);
    height: auto;
    bottom: 0;
    color: $white;
    padding: 1rem;
    box-sizing: border-box;
    font-size: $text-p;
    font-weight: 600;
    text-align: center;
    transform: translateY(-100%);
    transition: transform 0.3s;
    overflow: hidden;
    display: block;
    .text p {
      text-align: center!important;
    }
    .close {
      position: fixed;
      top: 1rem;
      right: 1rem;
      svg {
        height: 34px;
        width: auto;
        fill: $white;
      }
    }
    h2 {
      color: $primary;
      font-size: $text-p;
      width: 100%;
      font-weight: normal;
      text-align: center;
    }
    .logos {
      width: 100%;
      text-align: center;
      margin-top: 2rem;
      img {
        width: 100%;
        max-width: 300px;
        height: auto;
      }
    }
    .wrapper {
      overflow: hidden;
      height: 100%;
      max-width: 800px;
      margin: 0 auto;
      padding-top: 100px;
      padding-bottom: 1rem;
      position: relative;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &.is-open {
      transform: translateY(0%);
      overflow: auto;
      .pulldown {
        display: none;
      }
      .wrapper {
        overflow: auto;
      }
    }
  }
  @media only screen and (max-width: 767px) and (min-width: 500px) {
    .overlay {
      flex-direction: row;
      &.after-play {
        justify-content: center;
        align-items: flex-start;
        padding-top: 80px;
        div:first-child {
          margin: 0;
        }
        .icon img {
          width: 180px;
        }
        .icon:first-child {
          margin-right: 2rem;
        }
      }
    }
    .scan-info {
      .top {
        height: calc(50vh - 120px);
      }
      .bottom {
        height: calc(50vh - 80px);
        padding-top: 0;
        p {
          font-size: 1rem;
        }
      }
      .middle {
        height: 200px;
      }
      .side {
        width: calc(50% - 100px);
      }
      .scan {
        width: 200px;
        border-width: 20px;
      }
      img {
        width: 75px;
        height: auto;
      }
    }
  }
  </style>
