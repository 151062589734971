<template>
  <div class="c-scanner">
    <transition name="fade">
      <div v-if="!ready" class="loader">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
          <path d="M256 512c-68.38 0-132.667-26.629-181.020-74.98-48.351-48.353-74.98-112.64-74.98-181.020 0-48.419 13.591-95.567 39.303-136.346 25-39.648 60.332-71.686 102.178-92.65l21.5 42.916c-34.019 17.042-62.746 43.093-83.075 75.335-20.873 33.104-31.906 71.399-31.906 110.745 0 114.691 93.309 208 208 208s208-93.309 208-208c0-39.346-11.033-77.641-31.905-110.745-20.33-32.242-49.057-58.292-83.075-75.335l21.5-42.916c41.846 20.964 77.179 53.002 102.178 92.65 25.711 40.779 39.302 87.927 39.302 136.346 0 68.38-26.629 132.667-74.98 181.020-48.353 48.351-112.64 74.98-181.020 74.98z"></path>
        </svg>
      </div>
    </transition>
    <qrcode-stream v-show="ready && !initError" class="qr-scanner" @init="onInit" :track="false" @decode="onDecode">
      <div class="scan-info">
        <div class="top"></div>
        <div class="middle">
          <div class="side"></div>
          <div class="scan">
            <svg viewBox="0 0 512 512">
              <path d="m 10,126 c 5.523438,0 10,-4.47656 10,-10 V 20 h 96 c 5.52344,0 10,-4.476562 10,-10 C 126,4.476562 121.52344,0 116,0 H 10 C 4.476562,0 0,4.476562 0,10 v 106 c 0,5.52344 4.476562,10 10,10 z m 0,0" />
              <path d="m 126,502 c 0,-5.52344 -4.47656,-10 -10,-10 H 20 v -96 c 0,-5.52344 -4.476562,-10 -10,-10 -5.523438,0 -10,4.47656 -10,10 v 106 c 0,5.52344 4.476562,10 10,10 h 106 c 5.52344,0 10,-4.47656 10,-10 z m 0,0" />
              <path d="m 502,386 c -5.52344,0 -10,4.47656 -10,10 v 96 h -96 c -5.52344,0 -10,4.47656 -10,10 0,5.52344 4.47656,10 10,10 h 106 c 5.52344,0 10,-4.47656 10,-10 V 396 c 0,-5.52344 -4.47656,-10 -10,-10 z m 0,0" />
              <path d="M 502,0 H 396 c -5.52344,0 -10,4.476562 -10,10 0,5.523438 4.47656,10 10,10 h 96 v 96 c 0,5.52344 4.47656,10 10,10 5.52344,0 10,-4.47656 10,-10 V 10 C 512,4.476562 507.52344,0 502,0 Z m 0,0" />
            </svg>
            <img src="@/assets/images/qr_icon.svg" />
            <div class="line"></div>
          </div>
          <div class="side"></div>
        </div>
        <div class="bottom">
          <p v-if="qrError" class="qrerror">{{ qrError }}</p>
          <p>scan QR</p>
        </div>
      </div>
    </qrcode-stream>
    <div v-if="initError" class="init-error">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="768" height="768" viewBox="0 0 768 768">
        <path d="M736 384c0-97.184-39.424-185.248-103.104-248.896s-151.712-103.104-248.896-103.104-185.248 39.424-248.896 103.104-103.104 151.712-103.104 248.896 39.424 185.248 103.104 248.896 151.712 103.104 248.896 103.104 185.248-39.424 248.896-103.104 103.104-151.712 103.104-248.896zM672 384c0 79.552-32.192 151.488-84.352 203.648s-124.096 84.352-203.648 84.352-151.488-32.192-203.648-84.352-84.352-124.096-84.352-203.648 32.192-151.488 84.352-203.648 124.096-84.352 203.648-84.352 151.488 32.192 203.648 84.352 84.352 124.096 84.352 203.648zM537.6 492.8c0 0-4.192-5.536-10.592-12.256-4.64-4.896-10.816-10.912-18.464-17.28-10.784-8.992-24.704-18.88-41.504-27.296-22.56-11.264-50.528-19.968-83.040-19.968s-60.48 8.704-83.040 19.968c-16.8 8.416-30.72 18.304-41.504 27.296-7.648 6.368-13.824 12.384-18.464 17.28-6.4 6.72-10.592 12.256-10.592 12.256-10.592 14.144-7.744 34.208 6.4 44.8s34.208 7.744 44.8-6.4c1.312-1.6 5.792-6.592 5.792-6.592 3.2-3.36 7.584-7.648 13.056-12.224 7.776-6.496 17.568-13.408 29.12-19.2 15.36-7.648 33.6-13.184 54.432-13.184s39.072 5.536 54.432 13.216c11.52 5.76 21.344 12.704 29.12 19.2 5.472 4.576 9.856 8.864 13.056 12.224 4.48 4.96 5.792 6.56 5.792 6.56 10.592 14.144 30.656 16.992 44.8 6.4s16.992-30.656 6.4-44.8zM288 320c17.664 0 32-14.336 32-32s-14.336-32-32-32-32 14.336-32 32 14.336 32 32 32zM480 320c17.664 0 32-14.336 32-32s-14.336-32-32-32-32 14.336-32 32 14.336 32 32 32z"></path>
      </svg>
      {{ initError }}
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  data () {
    return {
      allowScan: false,
      qrResult: null,
      qrError: null,
      baseUrl: this.$store.getters.getBaseUrl,
      messages: this.$store.getters.getMessages,
      ready: false,
      initError: false,
    }
  },
  metaInfo: {
    title: 'QR tester',
  },
  components: {
    QrcodeStream,
  },
  mounted () {
    this.scanQR()
  },
  computed: {
    resultText () {
      return this.qrResult.replace(/-/g,' ')
    },
  },
  methods: {
    async onInit (promise) {
      // show loading indicator

      try {
        const { capabilities } = await promise

        // successfully initialized
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          // user denied camera access permisson
          this.initError = 'Eeny needs permission to use your camera. Please reload the page and try again.'
        } else if (error.name === 'NotFoundError') {
          // no suitable camera device installed
          this.initError = 'no suitable camera device installed'
        } else if (error.name === 'NotSupportedError') {
          // page is not served over HTTPS (or localhost)
          this.initError = 'page is not served over HTTPS'
        } else if (error.name === 'NotReadableError') {
          // maybe camera is already in use
          this.initError = 'maybe camera is already in use'
        } else if (error.name === 'OverconstrainedError') {
          // did you requested the front camera although there is none?
          this.initError = 'did you request the front camera although there is none?'
        } else if (error.name === 'StreamApiNotSupportedError') {
          // browser seems to be lacking features
          this.initError = 'Sorry, your browser is not supported...'
        }
      } finally {
        // hide loading indicator
        this.ready = true
      }
    },
    // found () {
    //   console.log('gehe');
    // },
    scanQR () {
      this.scanningQR = true
      // this.ready = true
    },
    stopScanningQR () {
      this.scanningQR = false
      this.qrResult = null
    },
    onDecode (decodedString) {
      console.log('decodedString:', decodedString)
      if (decodedString.includes(this.baseUrl)) {
        const chunk = decodedString.split('/')
        // console.log('chunk:', chunk)
        if (chunk) {
          this.qrError = false
          this.qrResult = chunk[chunk.length-1]
          console.log('this.qrResult:', this.qrResult)
          // setTimeout(() => {
            this.$router.push({ name: 'Vimeo', params: { slug: this.qrResult } })          
          // }, 1000);
        } else {
          this.qrError = 'not a valid eeny code, scan a different QR code'
          this.qrResult = null
        }
      } else {
        this.qrError = 'not a valid eeny url, scan a different QR code'
        this.qrResult = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .c-scanner {
    height: 100vh;
  }
  .qr-scanner {
    height: 100vh;
  }
  .scan-info {
    text-align: center;
    color: $secondary;
    height: 100%;
    .top,
    .side,
    .bottom {
      background-color: rgba(0,0,0,0.5);
    }
    .top,
    .bottom {
      height: calc(50vh - 150px);
    }
    .middle {
      height: 300px;
      display: flex;
    }
    .bottom {
      padding-top: 2rem;
    }
    .side {
      width: calc(50% - 150px);
    }
    .scan {
      width: 300px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        path {
          fill: $secondary;
        }
      }
      img {
        width: 100px;
        height: auto;
      }
    }
    .line {
      height: 2px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $secondary;
      animation: scan 2s ease-in-out infinite alternate;
    }
    p {
      padding: 0 1rem;
      font-size: $text-huge;
      font-weight: 600;
      margin-bottom: 0;
      &.qrerror {
        font-size: $text-medium;
        font-weight: 500;
        color: $error;
      }
    }
  }
  @keyframes scan {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(298px);
    }
  }
  @media only screen and (max-width: 767px) and (min-width: 500px) {
    .scan-info {
      .top {
        height: calc(50vh - 120px);
      }
      .bottom {
        height: calc(50vh - 80px);
        padding-top: 0;
        p {
          font-size: 1rem;
        }
      }
      .middle {
        height: 200px;
      }
      .side {
        width: calc(50% - 100px);
      }
      .scan {
        width: 200px;
        border-width: 20px;
      }
      img {
        width: 75px!important;
        height: auto;
        margin-top: 0;
      }
    }
    @keyframes scan {
      from {
        transform: translateY(0);
      }
      to {
        transform: translateY(198px);
      }
    }
  }
</style>